// TODO: update to show completed user rank information

import React from "react";
import PropTypes from "prop-types";
import { I18n } from "lib/I18n";
import { Settings } from "@salesforce/design-system-react";
import IconContext from "components/utils/IconContext";
import Sharing from './Sharing';

Settings.setAppElement("#main-wrapper");

export default class AgentblazerTrailCompletedModal extends React.Component {
  static propTypes = {
    description: PropTypes.string.isRequired,
    celebrationHeader: PropTypes.string.isRequired,
    footerCta: PropTypes.string.isRequired,
    agentblazerModalIconUrl: PropTypes.string.isRequired,
    sharing: PropTypes.object.isRequired,
  };

  state = {
    isOpen: this.props.isOpen
  };

  modal = React.createRef();

  closeModal = () => {
    this.trackModalClose();
    this.setState({ isOpen: false });
    this.resetFocus();
  };

  componentDidMount() {
    if (this.modal.current) {
      this.modal.current.addEventListener("requestclose", this.closeModal);
      window.addEventListener("lpstatechange", this.handleLpStateChange);
    }
  }

  handleLpStateChange = (event) => {
    if (event.detail.state === "agentblazerOpen") {
      this.setState({ isOpen: true });
    }
  };

  dispatchTrackEvent = (eventName, payload) => {
    const trackEvent = new CustomEvent("trailhead_track", {
      bubbles: true,
      composed: true,
      detail: {
        eventName,
        payload
      }
    });

    document.dispatchEvent(trackEvent);
  };

  trackModalClose = () => {
    const payload = {
      cta_type: "agentblazer_success_modal_dismissed",
      page_location: window.location.pathname
    };

    this.dispatchTrackEvent("modal_dismissed", payload);
  };

  trackCtaClick = () => {
    const payload = {
      cta_type: "agentblazer_view_dashboard",
      page_location: window.location.pathname
    };

    this.dispatchTrackEvent("cta_clicked", payload);
  };

  resetFocus = () => {
    document.querySelector(".th-content-container").focus();
  };

  localizePath = (path) => {
    return I18n.locale == "en" ? path : `${I18n.locale}${path}`;
  }

  render() {
    return (
      <IconContext>
        <div className="trail-completed-modal_outer">
          <tds-modal
            ref={this.modal}
            size="small"
            open={this.state.isOpen}
            assistive-text="trail completed"
            dismiss-on-click-outside={false}
          >
            <div slot="content">
              <div className="ab_trail-completed-modal">
                <div className="ab_data-dust-animation"></div>
                <div className="ab_trail-completed-modal_header">
                  <img
                    className="ab_trail-completed-modal_icon-image"
                    src={this.props.agentblazerModalIconUrl}
                    alt="trail completed"
                  />
                </div>
                <div className="ab_trail-completed-modal_content">
                  <h1 className="ab_trail-completed-modal_title">{this.props.celebrationHeader}</h1>
                  <div className="trail-completed-modal_description">{this.props.description}</div>
                </div>

                <div className="ab_footer--full-width">
                  <Sharing
                    appId={this.props.sharing.fbAppId}
                    content={{
                      description: this.props.sharing.content.description,
                    }}
                    public={true}
                    facebookText={this.props.sharing.facebookShareText}
                    twitterText={this.props.sharing.twitterShareText}
                    linkedInText={this.props.sharing.linkedinShareText}
                    shareUrl={this.props.sharing.shareUrl}
                    serverText={this.props.sharing.serverText}
                  />
                  <a
                    className="slds-button ab_button"
                    onClick={this.trackCtaClick}
                    href={this.localizePath('/agentblazer')}
                    size="medium"
                  >
                    {this.props.footerCta}
                  </a>
                </div>
              </div>
            </div>
          </tds-modal>
        </div>
      </IconContext>
    );
  }
}
