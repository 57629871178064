import React from 'react';
import PropTypes from 'prop-types';
import IconContext from 'components/utils/IconContext';
import { ButtonIcon } from '@devforce/tds-react';
import debounce from 'lodash/debounce';
import Favorites from 'lib/favorites';
import { t } from 'lib/I18n';
import GtmTracker, { transformPayload } from '../lib/tracking/trackGtm';

import trailheadSprite from '../icons/trailhead-sprite/svg/symbols.svg';

class FavoriteButton extends React.Component {
  static propTypes = {
    authenticated: PropTypes.bool.isRequired,
    favorited: PropTypes.bool.isRequired,
    type: PropTypes.oneOf(['module', 'project', 'trail', 'superbadge', 'learningpath'])
      .isRequired,
    apiName: PropTypes.string.isRequired,
    contentType: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.gtmTracker = new GtmTracker();
    this.state = {
      favorited: props.favorited
    };
  }

  getIconAssistiveText = () => (!this.state.favorited
    ? t('views.trailhead.growl.favorites.add')
    : t('views.trailhead.growl.favorites.remove'));

  trackInteraction = (eventAction) => {
    const { type, apiName } = this.props;
    const payload = {
      eventLabel: `${type}.${apiName}`,
      eventCategory: 'favorites',
      eventAction
    };

    this.gtmTracker.track(transformPayload(payload));
  }

  toggleFav = debounce((event) => {
    // always unfocus the element
    if(event) {
      event.target.blur();
    }

    const { type, apiName, contentType } = this.props;
    const previousFavoriteState = this.state.favorited;

    // initialize for a desired unfavored state
    let updateFavoriteParams = { type, apiName, favorited: true, contentType };
    let trackingFn = () => this.trackInteraction('favorite');

    this.setState((prevState) => ({ favorited: !prevState.favorited }));

    // transition from favored -> unfavored
    if (previousFavoriteState) {
      updateFavoriteParams = { type, apiName, favorited: false, contentType };
      trackingFn = () => this.trackInteraction('un_favorite');
    }
    Favorites.updateFavorite(updateFavoriteParams)
      .then(trackingFn)
      .catch(() => {
        this.setState({ favorited: previousFavoriteState });
      });
  },
  1000,
  { leading: true }
  );

  loginRedirect = () => {
    this.trackInteraction('signed_out');
    document.dispatchEvent(new CustomEvent('trailhead_login'));
  };

  render() {
    const { authenticated } = this.props;
    const { favorited } = this.state;
    const assistiveText = this.getIconAssistiveText();
    const onClickHandler = (event) => {
      if (event) {
        event.target?.focus();
      }
     return authenticated ? this.toggleFav() : this.loginRedirect();
    };
    
    return (
      <IconContext>
        <ButtonIcon
          title={assistiveText}
          aria-label={assistiveText}
          assistiveText={{
            icon: assistiveText,
          }}
          iconPath={`${trailheadSprite}#${
            favorited ? "star-fill" : "star-outline"
          }`}
          onClick={onClickHandler}
          iconClassName={favorited ? "tds-fill_brand" : ""}
          className="th-accessible-button_icon"
          name="favorite"
        />
      </IconContext>
    );
  }
}

export default FavoriteButton;
