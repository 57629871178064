// TODO: update to show completed user rank information

import React from "react";
import PropTypes from "prop-types";
import { Portal } from "react-portal";

import { Settings } from "@salesforce/design-system-react";
import IconContext from "components/utils/IconContext";
import Sharing from "./Sharing";
import RewardConfetti from "./RewardConfetti";
import WebComponent from "../WebComponent";

Settings.setAppElement("#main-wrapper");

export default class TrailCompletedModal extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    sharing: PropTypes.object,
    celebrationHeader: PropTypes.string.isRequired,
    footerCta: PropTypes.string.isRequired,
    trailsLink: PropTypes.string.isRequired,
    shareCta: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    inProgressTrails: PropTypes.array,
    preferences: PropTypes.object
  };

  state = {
    isOpen: this.props.isOpen,
    showFooterLinks: true
  };

  modal = React.createRef();

  closeModal = () => {
    this.setState({ isOpen: false });
    this.resetFocus();
  };

  componentDidMount() {
    if (this.modal.current) {
      this.modal.current.addEventListener("requestclose", this.closeModal);
      this.modal.current.addEventListener("statechange", this.handleCollectionStateChange);
      window.addEventListener("lpstatechange", this.handleLpStateChange);
    }
  }

  handleLpStateChange = (event) => {
    if (event.detail.state === "open") {
      this.setState({ isOpen: true });
    }
  };

  handleCollectionStateChange = (event) => {
    if (event.detail.state === "empty") {
      this.setState({ showFooterLinks: false });
      event.stopPropagation();
    }
  };

  resetFocus = () => {
    document.querySelector(".th-content-container").focus();
  };

  renderSharing = () => {
    return (
      <div>
        <Sharing
          appId={this.props.sharing.fbAppId}
          content={{
            title: this.props.title,
            description: this.props.description
          }}
          public={true}
          facebookText={this.props.sharing.facebook_share_text}
          twitterText={this.props.sharing.twitter_share_text}
        />
      </div>
    );
  };

  renderConfetti = () => {
    if (!this.state.isOpen) {
      return null;
    }

    return (
      <Portal>
        <RewardConfetti colorScheme={this.props.colorScheme} />
      </Portal>
    );
  };

  renderFooterLinks = () => {
    if (this.props.inProgressTrails.length) {
      return (
        <div className="trail-completed-modal_footer-header" style={{ margin: "10px 0 20px 0" }}>
          <div>{this.props.footerCta}</div>
          <a href="/trails">{this.props.trailsLink}</a>
        </div>
      );
    }

    if (this.state.showFooterLinks) {
      return (
        <div>
          <div className="trail-completed-modal_footer-header" style={{ margin: "10px 0 5px 0" }}>
            <div>Guided Learning</div>
            <a href="/trails">{this.props.trailsLink}</a>
          </div>
          <div style={{ margin: "0 0 10px 0" }}>Discover trails to kickstart your learning journey.</div>
        </div>
      );
    }
  };

  renderTrails = () => {
    if (!this.props.inProgressTrails.length) {
      return this.renderRecommendedTrails();
    }

    return this.renderInProgressTrails();
  };

  renderRecommendedTrails = () => {
    return <WebComponent tagName="th-recommended-trails" attrs={{ preferences: this.props.preferences }} />;
  };

  renderInProgressTrails = () => (
    <ul>
      {this.props.inProgressTrails.map((trail) => (
        <li className="trail-completed-modal_trail-list-item" key={trail.slug}>
          <img className="trail-completed-modal_icon-image--small" src={this.props.trailheadLogoUrl} alt="" />
          <div className="info">
            <div className="type">Trail</div>
            <a href={`/content/learn/trails/${trail.slug}`}>{trail.title}</a>

            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "15px" }}>
              <div className="points">+{trail.points}</div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <span className="progress-label">{Math.floor(trail.progress)}%</span>
                <div className="progress-bar" style={{ width: "50px" }}>
                  <div className="progress-bar-inner" style={{ width: `${trail.progress || 0}%` }}></div>
                </div>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );

  render() {
    return (
      <IconContext>
        <div className="trail-completed-modal_outer">
          <tds-modal
            ref={this.modal}
            size="x-small"
            open={this.state.isOpen}
            assistive-text="trail completed"
            dismiss-on-click-outside={false}
          >
            <div slot="content">
              <div className="trail-completed-modal">
                <h1>{this.props.celebrationHeader}</h1>
                <div>
                  <img
                    className="trail-completed-modal_icon-image"
                    src={this.props.trailheadLogoUrl}
                    alt="trail completed"
                  />
                </div>
                <div className="trail-completed-modal_title">{this.props.title}</div>
                <div className="trail-completed-modal_description">{this.props.shareCta}</div>

                <div>{this.renderSharing()}</div>
                <div className="slds-p-around_medium trail-completed-modal_footer-container">
                  {this.renderFooterLinks()}
                  <div>{this.renderTrails()}</div>
                </div>
              </div>
            </div>
          </tds-modal>
        </div>
        {this.renderConfetti()}
      </IconContext>
    );
  }
}
