import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'lib/I18n';
import { Badge } from '@devforce/tds-react';
import { Spinner } from '@salesforce/design-system-react';
import IconContext from 'components/utils/IconContext';

const createdOrConnected = ({ org_type }) => (
  org_type.match(/^developer/i)
    ? t('views.client.manage_hands_on_org.connected')
    : t('views.client.manage_hands_on_org.created')
);

const createdOrConnectedFallback = ({ org_type, created_at }) => {
  const label = org_type.match(/^developer/i)
    ? 'views.client.manage_hands_on_org.last_used_label_fallback_de'
    : 'views.client.manage_hands_on_org.last_used_label_fallback';

  return t(
    label,
    {
      date: new Date(created_at).toLocaleDateString(
        window.locale
      ),
    }
  );
};

const OrgDetails = ({ org, isWorking, color }) => (
  <IconContext>
    <div className="slds-grid">
      {isWorking && (
        <div
          className="slds-m-right--medium"
          style={{ minWidth: 40, zIndex: 0 }}
        >
          <Spinner
            size="x-small"
            variant="inverse"
            containerStyle={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '32px',
              height: '32px',
              backgroundColor: color,
              borderRadius: '50%',
              position: 'relative',
            }}
          />
        </div>
      )}

      <div className="slds-col slds-size--1-of-1">
        <div className="slds-text-heading--small slds-m-bottom--x-small" style={{ maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {org.label || org.name || org.type}

          {org.active_org && (
            <Badge
              label={t('views.client.manage_hands_on_org.active_org_badge')}
              variant="default"
              className="slds-m-left--small"
            />
          )}
        </div>

        {(org.signup_request_status || org.status) === 'pending' ? (
          <div className="slds-text-body--small th-color--meteorite">
            {t('views.client.manage_hands_on_org.pending')}
          </div>
        ) : (
          <dl className="th-dl slds-text-body--small">
            <dt>{t('views.client.manage_hands_on_org.organization_id')}</dt>
            <dd>{org.salesforce_organization_id?.substring(0,15)}</dd>

            <dt>{t('views.client.manage_hands_on_org.username')}</dt>
            <dd>{org.username}</dd>

            <dt>{t('views.client.manage_hands_on_org.type')}</dt>
            <dd>{org.type}</dd>

            <dt>
              { createdOrConnected({ org_type: org.type }) }
            </dt>
            <dd>
              {new Date(org.created_at).toLocaleDateString(window.locale)}
            </dd>

            {org.template_uri_present && org.expires_at && (
              <>
                <dt>{t('views.client.manage_hands_on_org.expires_at')}</dt>
                <dd>{new Date(org.expires_at).toLocaleString(window.locale)}</dd>
              </>
            )}

            <dt>{t('views.client.manage_hands_on_org.last_used')}</dt>
            <dd>
              {org.last_used_for
                // eslint-disable-next-line
                ? <span dangerouslySetInnerHTML={{
                  __html: t('views.client.manage_hands_on_org.last_used_label', {
                    ...org.last_used_for,
                    date: new Date(
                      org.last_used_for.used_on
                    ).toLocaleDateString(window.locale),
                  })
                }} />
                : createdOrConnectedFallback({ org_type: org.type, created_at: org.created_at })
              }
            </dd>
          </dl>
        )}
      </div>
    </div>
  </IconContext>
);

OrgDetails.propTypes = {
  org: PropTypes.object,
  color: PropTypes.string,
};

OrgDetails.defaultProps = {
  color: '#0070d2',
};

export default OrgDetails;
