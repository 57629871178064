import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'lib/I18n';

export default class Sharing extends React.Component {
  static propTypes = {
    appId: PropTypes.number.isRequired,
    content: PropTypes.object.isRequired,
    public: PropTypes.bool,
    facebookText: PropTypes.string,
    twitterText: PropTypes.string,
    linkedInText: PropTypes.string,
    shareUrl: PropTypes.string,
    serverText: PropTypes.bool, // flag to consume serverside rendered translations or interpolate
  };

  static defaultProps = {
    public: true,
    facebookText: 'views.client.share.one_badge_text.facebook',
    twitterText: 'views.client.share.one_badge_text.twitter',
    serverText: false,
  };

  onClick = (e) => {
    e.preventDefault();
    const width = e.currentTarget.getAttribute('data-width');
    const height = e.currentTarget.getAttribute('data-height');
    const props = `width=${width},height=${height}`;
    window.open(e.currentTarget.getAttribute('href'), '_blank', props);
    // TODO: use trailhead_track cta_click
    return false;
  };

  render() {
    const { appId, content, facebookText, twitterText, linkedInText } = this.props;

    let url;
    if(this.props.shareUrl) {
      url = window.encodeURIComponent(this.props.shareUrl);
    } else {
      url = window.encodeURIComponent(window.location.href);
    }

    const description = window.encodeURIComponent(content.description);

    const translatedFacebookText = this.props.serverText ? facebookText : t(facebookText, { badge: content.title });

    const facebookUrl = `https://www.facebook.com/dialog/share?app_id=${appId}&display=popup&href=${url}&description=${description}&quote=${window.encodeURIComponent(
      translatedFacebookText
    )}`;

    const translatedTwitterText = this.props.serverText ? twitterText : t(twitterText, { badge: content.title });

    const twitterUrl = `https://twitter.com/share?url=${url}&text=${window.encodeURIComponent(translatedTwitterText)}`;

    const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;

    return (
      <ul className="th-sharing">
        {this.props.public && (
          <li>
            <a
              onClick={this.onClick}
              className="th-sharing__link"
              data-height="570"
              data-width="520"
              href={facebookUrl}
              data-network="Facebook"
            >
              <svg viewBox="0 0 40 40" className="th-sharing__icon th-sharing__icon--facebook">
                <path d="M20,0C9,0,0,9,0,20s6.5,17.2,15.2,19.4v-13.3h-4.1v-6.1h4.1v-2.6c0-6.8,3.1-10,9.8-10s3.5.2,4.3.5v5.5c-.5,0-1.3,0-2.3,0-3.3,0-4.5,1.2-4.5,4.5v2.2h6.5l-1.1,6.1h-5.4v13.7c9.9-1.2,17.6-9.6,17.6-19.8S31,0,20,0Z" />

                <path
                  className="th-sharing__icon--facebook-inner"
                  d="M15.2,17.4v2.6h-4.1v6.1h4.1v13.3c1.5.4,3.2.6,4.8.6s1.6,0,2.4-.2v-13.7h5.4l1.1-6.1h-6.5v-2.2c0-3.2,1.3-4.5,4.5-4.5s1.8,0,2.3,0v-5.5c-.9-.2-3.1-.5-4.3-.5-6.7,0-9.8,3.2-9.8,10Z"
                />
              </svg>
              <span className="slds-assistive-text">Facebook</span>
            </a>
          </li>
        )}
        {this.props.public && (
          <li>
            <a
              onClick={this.onClick}
              className="th-sharing__link"
              data-height="570"
              data-width="520"
              href={twitterUrl}
              data-network="Twitter"
            >
              <svg className="th-sharing__icon th-sharing__icon--twitter" viewBox="0 0 40 40">
                <circle cx="20" cy="20" r="20" />
                <path
                  className="th-sharing__icon--twitter-inner"
                  d="M21.7,18.6l6.6-7.7h-1.6l-5.8,6.7-4.6-6.7h-5.3l6.9,10.1-6.9,8.1h1.6l6.1-7.1,4.9,7.1h5.3l-7.2-10.5h0ZM19.5,21.1l-.7-1-5.6-8h2.4l4.5,6.5.7,1,5.9,8.4h-2.4l-4.8-6.9h0Z"
                />
              </svg>
              <span className="slds-assistive-text">Twitter</span>
            </a>
          </li>
        )}
        {this.props.public && this.props.linkedInText && (
          <li>
            <a
              className="th-sharing__link"
              data-height="570"
              data-width="520"
              href={linkedInUrl}
              data-network="LinkedIn"
            >
              <svg className="th-sharing__icon th-sharing__icon--linkedin" viewBox="0 0 32 32">
                <path
                  d="M16 0C7.2 0 0 7.2 0 16C0 24.8 7.2 32 16 32C24.8 32 32 24.8 32 16C32 7.2 24.8 0 16 0ZM11.2 24.6545H7.56364V13.0909H11.2V24.6545ZM9.38182 11.4909C8.21818 11.4909 7.27273 10.6182 7.27273 9.38182C7.27273 8.14545 8.21818 7.27273 9.38182 7.27273C10.5455 7.27273 11.4909 8.14545 11.4909 9.38182C11.4909 10.6182 10.5455 11.4909 9.38182 11.4909ZM24.7273 24.7273H21.0909V18.4C21.0909 16.5091 20.2909 15.9273 19.2727 15.9273C18.1818 15.9273 17.0909 16.7273 17.0909 18.4V24.7273H13.4545V13.0909H16.9455V14.6909H17.0182C17.3818 13.9636 18.6182 12.7273 20.5091 12.7273C22.5455 12.7273 24.7273 13.9636 24.7273 17.4545V24.7273Z"
                  fill="white"
                />
              </svg>
              <span className="slds-assistive-text">LinkedIn</span>
            </a>
          </li>
        )}
      </ul>
    );
  }
}
