import React from "react";
import PropTypes from "prop-types";

export default function CardContentButtons({ buttons, contentType }) {
  return (
    <div className="slds-grid">
      {buttons.map((btn, i) => (
        // eslint-disable-next-line react/no-array-index-key
        (contentType !== "trail_v2" || i === 0) ?
        <div key={i} className="th-content-card_action-bar-button">
          {btn}
        </div> : null
      ))}
    </div>
  );
}

const { arrayOf, node } = PropTypes;

CardContentButtons.propTypes = {
  buttons: arrayOf(node).isRequired,
  contentType: String.isRequired
};
