import React from 'react';
import PropTypes from 'prop-types';
import wrapProvider from 'lib/wrapProvider';
import track from "lib/tracking/track";
import { store } from 'reducers';
import { getAuthToken, default as railsFetch } from 'lib/railsFetch';
import { t } from 'lib/I18n';
import EmailSelect from "components/challenge/EmailSelect";
import WebComponent from 'components/WebComponent';

const externalAccountMetadata = {
  'mulesoft_anypoint_account': {
    accountType: 'mulesoft',
    connectUrl: '/auth/mulesoft_anypoint',
    getLaunchPath: () => 'https://anypoint.mulesoft.com/home/',
  },
  'slack_developer_account': {
    accountType: 'slack',
    connectUrl: '/auth/slack',
    getLaunchPath: (developerId) => `https://api.slack.com/developer-program/trailhead/sandbox/signin?developer_id=${developerId}`,
  }
}

export class ExternalAccountConnector extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    requirements: PropTypes.array.isRequired,
    uuid: PropTypes.string,
    questions: PropTypes.array,
    metadata: PropTypes.shape({
      authorization_requirements: PropTypes.shape({
        account_type: PropTypes.string.isRequired,
      }),
    }),
    authorized_account_type: PropTypes.string.isRequired,
    authorized_accounts: PropTypes.array,
    accountConnected: PropTypes.bool.isRequired,
    isDisconnectAccountDisabled: PropTypes.bool.isRequired,
    onAccountDisconnect: PropTypes.func.isRequired,
    disconnectError: PropTypes.string,
    moduleApiName: PropTypes.string.isRequired,
    unitApiName: PropTypes.string.isRequired,
  };

  state = {
    disableConnectButton: false,
    authorizedAccount: null,
    authorizedAccountStatus: 'unauthorized',
    errorMsg: '',
    selectedAccount: '',
    newsletterChecked: false,
    tosChecked: false,
  };

  accountMetadata = externalAccountMetadata[this.props.authorized_account_type];

  isSlackUnifiedIdentity = this.props.slack_unified_identity_enabled && this.accountMetadata?.accountType === 'slack';

  componentDidMount() {
    // Throw an error if we encountered an unsupported account type
    if (!this.accountMetadata) {
      throw new Error(`Unsupported account type: ${this.props.authorized_account_type}`);
    }
    const accountType = this.accountMetadata?.accountType;
    const authorizedAccount = this.props.authorized_accounts && this.props.authorized_accounts[0];
    if (authorizedAccount) {
      this.setState({
        authorizedAccount,
        authorizedAccountStatus: authorizedAccount.status,
      });
    }
    if (this.props.authorized_email_accounts && this.props.authorized_email_accounts[0]) {
      this.setState({
        selectedAccount: this.props.authorized_email_accounts[0],
    });
    }
    if ((!this.props.slack_unified_identity_enabled || accountType !== 'slack') && authorizedAccount?.status === 'invalid') {
      this.setState({
        errorMsg: t(`challenge.assessment.${accountType}.reconnect_prompt`),
      });
    }
  }

  // Since this component is being used inside another form element and we can't
  // have nested form elements, we create a new form element on the document in order
  // to interact with the mulesoft endpoints.
  post(path) {
    const form = document.createElement('form');
    form.method = 'post';
    form.action = path;

    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = 'authenticity_token';
    hiddenField.value = getAuthToken();

    form.appendChild(hiddenField);

    document.body.appendChild(form);
    form.submit();
  }

  onLaunchClick(accountType) {
    const launchButtonClickedEvent = {
      eventName: 'tpo_launch_clicked',
      payload: {
        pageLocation: 'external_account_connector',
        moduleApiName: this.props.moduleApiName,
        unitApiName:  this.props.unitApiName,
        playgroundInfo: {
          playgroundType: `${accountType.charAt(0).toUpperCase() + accountType.slice(1)} Playground`,
        }
      },
    };
    track(launchButtonClickedEvent.eventName, launchButtonClickedEvent.payload);
  }

  handleEmailSelect(email) {
    const accountType = this.accountMetadata?.accountType;
    this.setState({
      selectedAccount: email,
      disableConnectButton: true,
    });
    railsFetch({
      url: `${this.accountMetadata?.connectUrl}/developer_account_lookup`,
      method: "post",
      data: {
        email,
      }
    })
    .then((response) => {
      this.setState({
        errorMsg: null,
        authorizedAccount: response,
        authorizedAccountStatus: response.status || 'unauthorized'
      });
    })
    .catch((error) => {
      let errorMsg = t(`challenge.assessment.${accountType}.error.lookup`);
      if (error.error === "invalid_email") {
        errorMsg = t(`challenge.assessment.${accountType}.error.invalid_email`, {invalid_email: email});
      }
      else {
        window.onerror({
          message: new Error(JSON.stringify(error)),
          filename: 'ExternalAccountConnector.jsx'
        });
      }
      this.setState({
        authorizedAccountStatus: 'unauthorized',
        errorMsg
      });
    })
    .finally(() => {
      this.setState({
        disableConnectButton: false,
      })
    })
  }

  handleSubmit(e) {
    e.preventDefault();
    const authorizedAccount = this.state.authorizedAccount || this.props.authorized_accounts[0];
    const accountType = this.accountMetadata?.accountType;
    if (this.isSlackUnifiedIdentity) {
      if (this.state.authorizedAccountStatus === 'unauthorized') {
        railsFetch({
          url: `${this.accountMetadata?.connectUrl}/developer_account_create`,
          method: "post",
          data: {
            email: this.state.selectedAccount,
            tos: this.state.tosChecked,
            newsletter_subscription: this.state.newsletterChecked,
          }
        })
        .then((response) => {
          if (response.redirect_uri) {
            window.location.href = response.redirect_uri;
          } else {
            this.setState({
              errorMsg: t(`challenge.assessment.${accountType}.error.creating`)
            });
          }
        })
        .catch((error) => {
          let errorMsg = t(`challenge.assessment.${accountType}.error.creating`);
          if (error.error === "invalid_email") {
            errorMsg = t(`challenge.assessment.${accountType}.error.invalid_email`, {invalid_email: this.state.selectedAccount});
          }
          else {
            window.onerror({
              message: new Error(JSON.stringify(error)),
              filename: 'ExternalAccountConnector.jsx'
            });
          }
          this.setState({
            errorMsg
          });
        })
        .finally(() => {
          this.setState({
            disableConnectButton: false,
          })
        })
      } else if (this.state.authorizedAccountStatus === 'invalid') {
        this.post(`${this.accountMetadata?.connectUrl}?developer_id=${authorizedAccount.id}&sig=${authorizedAccount.sig}`);
      }
    } else {
      this.post(this.accountMetadata?.connectUrl);
    }
  }

  ctaButtonLabel(accountType) {
    if (this.isSlackUnifiedIdentity) {
      return this.state.authorizedAccountStatus === 'invalid'
        ? t(`challenge.assessment.${accountType}.connect_button`)
        : t(`challenge.assessment.${accountType}.create_button`)
    }
    return this.state.errorMsg
      ? t(`challenge.assessment.${accountType}.reconnect_button`)
      : t(`challenge.assessment.${accountType}.connect_button`)
  }

  renderExternalAccountSelector() {
    return (
      <div className='th-challenge__external-account-selector'>
        <EmailSelect emails={this.props.authorized_email_accounts || []} onChange={(email) => {
          this.handleEmailSelect(email)
        }}></EmailSelect>
        {this.state.authorizedAccountStatus === 'unauthorized' && <>
          <WebComponent
            tagName="tds-input"
            attrs={{
              type: 'checkbox',
              label: t(`challenge.assessment.slack.developer_tos_checkbox`, { link: "https://slack.com/terms-of-service/slack-developer-program" }),
              value: 'tos',
              checked: this.state.tosChecked,
              required: true,
              onchange: (e) => {
                this.setState({disableConnectButton: !e.detail, tosChecked: e.detail});
              }
            }}
          />
          <WebComponent
            tagName="tds-input"
            attrs={{
              type: 'checkbox',
              label: t(`challenge.assessment.slack.newsletter_checkbox`),
              value: 'newsletter',
              checked: this.state.newsletterChecked,
              onchange: (e) => {
                this.setState({newsletterChecked: e.detail});
              }
            }}
          />
        </>}
      </div>
    )
  }

  renderLoggedOutState() {
    const accountType = this.accountMetadata?.accountType;
    return (
      <>
        <p className='slds-m-bottom--small'>
          {t(`challenge.assessment.${accountType}.connect_prompt`)}
        </p>
        {this.state.errorMsg && (
          <p className='slds-m-bottom--small slds-text-color_error'>
            {this.state.errorMsg}
          </p>
        )}
        <div className='th-challenge__external-account-container'>
          {this.isSlackUnifiedIdentity && this.renderExternalAccountSelector()}
          <button
            id='th-challenge__external-account-connect-btn'
            onClick={(e) => {
              this.setState({ disableConnectButton: true });
              this.handleSubmit(e);
            }}
            disabled={this.state.disableConnectButton || (this.isSlackUnifiedIdentity && !this.state.tosChecked && this.state.authorizedAccountStatus === 'unauthorized')}
            className='slds-button th-button th-button--primary th-challenge__footer-cta'
          >
            {this.ctaButtonLabel(accountType)}
          </button>
        </div>
      </>
    );
  }

  renderLoggedInState() {
    const authorizedAccount = this.props.authorized_accounts[0];
    const accountType = this.accountMetadata?.accountType;
    const launchPath = accountType === 'slack' ? this.accountMetadata?.getLaunchPath(authorizedAccount.id) : this.accountMetadata?.getLaunchPath();
    const containerClass = this.state.authorizedAccountStatus === 'valid' ? 'th-challenge__external-account-selected' : '';
    return (
      <>
        <p className='slds-m-bottom--small'>
          {t(`challenge.assessment.${accountType}.connected_prompt`)}
        </p>
        <div className={`th-challenge__external-account-container ${containerClass}`}>
          <div>
            <p>
              <strong>{authorizedAccount.username || authorizedAccount.email}</strong>
            </p>
            <p className='th-challenge__external-account-org'>
              {authorizedAccount.organization?.name || ''}
            </p>
          </div>
          <div>
            <button
              onClick={this.props.onAccountDisconnect}
              disabled={this.props.isDisconnectAccountDisabled}
              className='slds-button th-button slds-button_text-destructive th-challenge__footer-cta'
            >
              {t(`challenge.assessment.${accountType}.disconnect_button`)}
            </button>
            <a
              href={launchPath}
              target='_blank'
              rel='noopener noreferrer'
              onClick={() => this.onLaunchClick(accountType)}
              className='slds-button th-button slds-button_brand th-challenge__footer-cta'
            >
              {t(`challenge.assessment.${accountType}.launch_button`)}
            </a>
          </div>
        </div>
        {this.props.disconnectError && (
          <p className='slds-m-top_small th-challenge__external-account-disconnect-error'>
            {this.props.disconnectError}
          </p>
        )}
      </>
    );
  }

  render() {
    if (this.props.accountConnected) {
      return this.renderLoggedInState();
    }
    return this.renderLoggedOutState();
  }
}

export default wrapProvider({
  store,
  mapStateToProps: (state) => state.orgPicker,
})(ExternalAccountConnector);
