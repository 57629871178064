import React from 'react';
import PropTypes from 'prop-types';
import railsFetch from 'lib/railsFetch';
import { buildEvaluation } from 'lib/handsOnEvaluation';
import { autoFollowTrailmix } from 'lib/followTrailmix';
import { t, I18n } from 'lib/I18n';
import provideContext from 'lib/provideContext';
import wrapProvider from 'lib/wrapProvider';
import { store } from 'reducers';
import { initOrgPicker, setSelectedOrg } from 'actions/orgPicker';
import { SOURCE_ASSESSMENT_ATTEMPTED } from 'actions/trailmix/events_constants';
import IconContext from 'components/utils/IconContext';
import Assessment from './Assessment';
import DOMPurify from 'dompurify';

export class AssessmentContainer extends React.Component {
  static propTypes = {
    msaSigned: PropTypes.bool.isRequired,
    apiName: PropTypes.string.isRequired,
    authenticated: PropTypes.bool.isRequired,
    points: PropTypes.number.isRequired,
    completeChallenge: PropTypes.func.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    requirements: PropTypes.array,
    moduleApiName: PropTypes.string.isRequired,
    unitType: PropTypes.string.isRequired,
    retake: PropTypes.bool.isRequired,
    tpHelpLink: PropTypes.string.isRequired,
    selectedOrg: PropTypes.object,
    hideOrgPicker: PropTypes.bool,
    trailmix: PropTypes.object,
    evaluationContext: PropTypes.object.isRequired,
    disconnectMulesoftAnypointAccountPath: PropTypes.string.isRequired,
    previewMode: PropTypes.bool,
    unitUid: PropTypes.string,
  };

  state = {
    disabled: !this.props.authenticated,
    isDisconnectAccountDisabled: false,
    points: this.props.points,
    isSubmitting: false,
    errorText: null,
    disconnectError: null,
    submitError: false,
    statusCode: null,
    accountConnected: false,
  };

  get customTemplateRequired() {
    return this.props.evaluationContext.metadata?.authorization_requirements?.custom_template_required;
  }

  get externalAccountProps() {
    return {
      accountConnected: this.state.accountConnected,
      authorizedEmailAccounts: this.props.authorizedEmailAccounts,
      isDisconnectAccountDisabled: this.state.isDisconnectAccountDisabled,
      onAccountDisconnect: this.onAccountDisconnect,
      disconnectError: this.state.disconnectError,
    }
  }

  evaluationParams() {
    return {
      data: {
        first_time: true,
        retake: this.props.retake,
        is_step: true
      }
    }
  }

  onComplete = ({data: {completed, message, points, rewards}}) => {
    this.setState({
      isSubmitting: false
    });

    if (completed) {
      this.props.completeChallenge(true, points, rewards);
    } else {
      this.setState({errorText: message});
    }
  }

  onError = ({errorCode, error}) => {
    const errorText = (typeof error === 'string') ? error : t("challenge.assessment.error.unavailable");
    this.setState({
      submitError: true,
      isSubmitting: false,
      statusCode: errorCode,
      errorText
    });
  }

  componentDidMount() {
    if (this.props.evaluationContext.authorized_accounts[0] && this.props.evaluationContext.authorized_accounts[0].status === "valid") {
      this.setState({accountConnected: true});
    }
  }

  onAssessmentSubmit = (e) => {
    e.preventDefault();
    if (this.state.disabled) {
      return;
    }

    this.setState({
      isSubmitting: true,
      submitError: false,
      statusCode: null,
      errorText: null
    });

    autoFollowTrailmix(this.props.parent_trailmix, SOURCE_ASSESSMENT_ATTEMPTED);
    const evaluationParams = this.evaluationParams();
    buildEvaluation(this.props)
      .start({...evaluationParams, ...{interval: 15000, maxRetries: 20}})
      .then(this.onComplete, this.onError);
  };

  onAccountDisconnect = (e) => {
    e.preventDefault();
    this.setState({ isDisconnectAccountDisabled: true });
    const accountType = this.props.evaluationContext.authorized_account_type === 'slack_developer_account' ? 'slack' : 'mulesoft';
    const url = accountType === 'slack' ? '/auth/slack/logout' : this.props.disconnectMulesoftAnypointAccountPath;
    railsFetch({ url, method: 'delete' })
      .then(() => this.setState({ accountConnected: false, disconnectError: null }))
      .catch(({ message }) => {
        this.setState({
          disconnectError: message || t(`challenge.assessment.${accountType}.disconnect_error_msg`),
          isDisconnectAccountDisabled: false,
        })});
  }  

  setSelectedOrg = (org) => {
    this.setState({
      errorText: org ? this.state.errorText : null
    });
  };

  isSubmitDisabled = () => {
    if (this.props.hideOrgPicker) {
      return false;
    }

    if (!this.props.selectedOrg) {
      return true;
    }

    const authorized_account = this.props.evaluationContext.authorized_accounts.find(account => account.template_match)
    const authorized_account_type = this.props.evaluationContext.authorized_account_type;
    // Keep the Verify submit button disabled on OrgFarm units/steps unless the user has an active OrgFarm org connected to their account.
    if (authorized_account_type === 'salesforce_org' && this.customTemplateRequired && this.props.evaluationContext.inventory_limited_custom_org_template_required && !authorized_account?.active_org) {
      return true;
    }

    if (['mulesoft_anypoint_account', 'slack_developer_account'].includes(authorized_account_type) && !this.state.accountConnected) {
      return true;
    }
    
    return (
      this.props.selectedOrg.signup_request_status === 'pending' ||
      this.state.isSubmitting
    );
  };

  renderHelperText = () => {
    let message = 'There was an issue processing your verification. Please refresh the page and try again.';

    if (this.state.submitError) {
      message = this.state.errorText || message;
      return (
        <span className="slds-text-color_error" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }} />
      );
    }

    if (this.state.errorText) {
      return <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.errorText) }} />;
    }
    return '';
  };

  renderAssessment = (evaluationContext) => {
    return <Assessment
      {...this.props}
      points={this.state.points}
      onSubmit={this.onAssessmentSubmit}
      isSubmitting={this.state.isSubmitting}
      isSubmitDisabled={this.isSubmitDisabled}
      selectedOrg={this.props.selectedOrg}
      renderHelperText={this.renderHelperText}
      requirements={this.props.requirements}
      tpHelpLink={this.props.tpHelpLink}
      hideOrgPicker={this.props.hideOrgPicker}
      evaluationContext={this.props.evaluationContext}
      externalAccountProps={this.externalAccountProps}
      customTemplateRequired={this.customTemplateRequired}
      unitUid={this.props.unitUid}
    />
  }

  render() {
    return (
      <IconContext>
         {this.renderAssessment(this.props.evaluationContext)}
      </IconContext>
    );
  }
}

export default provideContext(
  wrapProvider({
    store,
    initAction: initOrgPicker,
    mapStateToProps: (state) => state.orgPicker,
    mapDispatchToProps: (dispatch) => ({
      setSelectedOrg(org) {
        dispatch(setSelectedOrg(org));
      }
    }),
  })(AssessmentContainer)
);
