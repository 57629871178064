import React from 'react';
import FavoriteButton from 'components/FavoriteButton';
import MoreTagsDropdown from 'components/tile/MoreTagsDropdown';
import TrailmixesPopover from 'components/trailmixes/TrailmixesPopover/TrailmixesPopover';
import getDisplayName from 'components/utils/getDisplayName';
import IconContext from 'components/utils/IconContext';
import FacetsDropdown from 'components/FacetsDropdown';

const withContentProps = (WrappedComponent) => {
  class WithContentProps extends React.Component {
    buttons() {
      const {
        user,
        allFacets,
        onAddFacet,
        trailmixes,
        content: { apiName, type, tags, facets, contentType }
      } = this.props;
      if (type === 'customstep' || type === 'trailmix') return null;
      const authenticated = !!user;
      const isFavorite = !!(this.props.user && this.props.user.favorited);
      const trailmixesPopoverProps = this.trailmixesPopOverProps();
      const favKey = `${type}-${apiName}-${isFavorite}`;
      const buttons = [];

      if (type !== 'preview_module') {
        buttons.push(
          <FavoriteButton
            favorited={isFavorite}
            authenticated={authenticated}
            apiName={apiName}
            contentType={contentType}
            type={type}
            key={favKey}
          />
        );
      }

      if (trailmixes) {
        buttons.push(<TrailmixesPopover {...trailmixesPopoverProps} />);
      }

      if (tags) {
        const displayOnly = type === 'preview_module';
        buttons.push(<MoreTagsDropdown items={tags} displayOnly={displayOnly} />);
      }

      if (facets) {
        buttons.push(
          <FacetsDropdown
            facets={facets}
            allFacets={allFacets}
            onAddFacet={onAddFacet}
          />
        );
      }

      return buttons;
    }

    trailmixesPopOverProps() {
      const {
        trailmixes, trailmixButtonLabels, user,
        assetPaths: { loader },
        content: { apiName, type, contentUid }
      } = this.props;
      const authenticated = !!user;

      return {
        trailmixes,
        user_slug: authenticated ? user.slug : '',
        current_user: authenticated,
        imageLoaderUrl: loader,
        api_name: apiName,
        content_type: type,
        content_uid: contentUid,
        align: 'top left',
        labels: trailmixButtonLabels,
      };
    }

    render() {
      const { expanded, units, ...passThroughProps } = this.props;

      const buttons = this.buttons();

      return (
        <IconContext>
          <WrappedComponent
            {...passThroughProps}
            buttons={buttons}
            units={units}
            unitsInitiallyExpanded={expanded}
          />
        </IconContext>
      );
    }
  }

  WithContentProps.displayName = `WithContentProps(${getDisplayName(
    WrappedComponent
  )})`;

  return WithContentProps;
};

export default withContentProps;
