import React from 'react';
import PropTypes from 'prop-types';
import wrapProvider from 'lib/wrapProvider';
import { store } from 'reducers';
import { setSelectedOrg } from 'actions/orgPicker';
import { t } from 'lib/I18n';

export class OrgPickerContainer extends React.Component {
  static propTypes = {
    apiName: PropTypes.string,
    moduleApiName: PropTypes.string,
    unitUid: PropTypes.string,
    isCustomDeOrg: PropTypes.bool,
    templateUri: PropTypes.string,
    orgs: PropTypes.array.isRequired,
    setSelectedOrg: PropTypes.func.isRequired,
    previewMode: PropTypes.bool,
  };

  state = {
    orgs: this.props.orgs
  };

  thOrgPicker = React.createRef();

  componentDidMount() {
    // see if any orgs are pending, and if so continue polling
    if (!this.props.orgs) {
      return;
    }

    this.thOrgPicker.current.addEventListener('load', ({ detail: org }) => {
      this.props.setSelectedOrg(org);
    });

    this.thOrgPicker.current.addEventListener('change', ({ detail: org }) => {
      this.props.setSelectedOrg(org);
    });

    this.thOrgPicker.current.addEventListener('createend', ({ detail: org }) => {
      this.props.setSelectedOrg(org);
    });

    this.thOrgPicker.current.addEventListener('error', () => {
      // Disable the submit button if something goes wrong
      this.props.setSelectedOrg(null);
    });
  }

  render() {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    return (
      <th-org-picker
        label={t('org_picker.field_label')}
        ref={this.thOrgPicker}
        lang={window.locale}
        csrf-token={csrfToken}
        module-api-name={this.props.moduleApiName}
        unit-api-name={this.props.apiName}
        unit-uid={this.props.unitUid}
        is-custom-de-org={this.props.isCustomDeOrg}
        template-uri={this.props.templateUri}
        autoswitch={this.props.previewMode ? 'off' : 'on'}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setSelectedOrg(org) {
      dispatch(setSelectedOrg(org));
    }
  };
}

export default wrapProvider({
  store,
  mapStateToProps: (state) => state.orgPicker,
  mapDispatchToProps,
})(OrgPickerContainer);
