import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  DataTableCell,
  DropdownMenu,
  Icon,
  Tooltip
} from '@devforce/tds-react';
import CategoryDeleteModal from './CategoryDeleteModal';
import { I18n, buildScopeTranslate } from '../../../../lib/I18n';

const tCat = buildScopeTranslate('views.trailmaker.settings.category.table');

const valueInLocale = (locale, translations) => {
  // Prefer value in current locale. Fall back to English if not available.
  const t = translations.find((translation) => translation.locale === locale) ||
    translations.find((translation) => translation.locale === 'en');
  return t ? t.value : '';
};

const prepareCategories = (locale, categories, limit) => {
  const preparedCategories = [];

  for (let i = 0; i < limit; i++) { // eslint-disable-line no-plusplus
    let childCategories = [];
    if (categories[i].children && categories[i].children.length > 0) {
      childCategories = categories[i].children;
    }

    preparedCategories[i] = {
      id: categories[i].id,
      name: valueInLocale(locale, categories[i].name),
      protected: !!categories[i].protected,
      children: prepareCategories(locale, childCategories, childCategories.length),
    };
  }

  return preparedCategories;
};

const ActionsDataCell = ({
  item,
  onRequestDelete,
  onRequestEdit,
  onRequestAddChild,
  groupAPIName,
  publicTrailmaker,
  hasNestedChild,
  isChild,
  ...rest
}) => {
  const tooltipContent = () => (item.protected ? tCat(`actions.delete_tooltip.${groupAPIName}`) : '');

  const MENU_OPTIONS_DEFAULT = [
    {
      label: tCat('actions.delete'),
      value: 'delete',
      disabled: !!item.protected,
      tooltipContent: tooltipContent(),
    }
  ]
  const MENU_OPTIONS_PUBLIC_TRAILHEAD = [
    {
      label: tCat('actions.edit'),
      value: 'edit',
    },
    ...MENU_OPTIONS_DEFAULT,
  ]
  const MENU_OPTIONS_LEVEL1_PUBLIC_TRAILHEAD = [
    {
      label: tCat('actions.edit'),
      value: 'edit',
    },
    {
      label: tCat('actions.add_child_filter'),
      value: 'add_child_filter',
    },
    ...MENU_OPTIONS_DEFAULT,
  ]
  const public_options = hasNestedChild ? MENU_OPTIONS_LEVEL1_PUBLIC_TRAILHEAD : MENU_OPTIONS_PUBLIC_TRAILHEAD;
  const options = publicTrailmaker ? public_options : MENU_OPTIONS_DEFAULT;

  return (
      <DropdownMenu
        assistiveText={{
          icon: 'More Options'
        }}
        iconCategory="utility"
        iconName="down"
        iconVariant="border-filled"
        buttonClassName={isChild ? "tds-button_icon noborder-filled" : "tds-button_icon" }
        onSelect={(opt) => {
          switch (opt.value) {
            case 'edit':
              if (item.protected) break;
              onRequestEdit(item.id, item.name, !!item.protected);
              break;
            case 'add_child_filter':
              if (item.protected) break;
              onRequestAddChild(item.id, item.name, !!item.protected);
              break;
            case 'delete':
              if (item.protected) break;
              onRequestDelete(item.id, item.name, !!item.protected, item.children.length > 0);
              break;
            default:
              break;
          }
        }}
        width="xx-small"
        align="right"
        options={options}
        menuPosition="overflowBoundaryElement"
        tooltipMenuItem={<Tooltip position="overflowBoundaryElement" />}
      />
  );
};

ActionsDataCell.displayName = DataTableCell.displayName;

const CategoryTable = ({
  groupId,
  groupAPIName,
  name,
  categories,
  onDeleteCategory,
  onAddCategory,
  onEditCategory,
  onAddChildCategory,
  canAddCategory,
  publicTrailmaker,
  hasNestedChild,
}) => {
  const COLLAPSED_MAX_ITEMS = 10;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [itemRequestedForDeletion, setItemRequestedForDeletion] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [rowsCollapsed, setRowsCollapsed] = useState({});

  const onRequestDeleteCategory = (id, categoryName, isProtected, hasChildren) => {
    setItemRequestedForDeletion({ id, categoryName, isProtected, hasChildren });
    setIsDeleteModalOpen(true);
  };

  const onCancelDeleteCategory = () => {
    setItemRequestedForDeletion(null);
    setIsDeleteModalOpen(false);
  };

  const onCompletedDeleteCategory = () => {
    setIsDeleteModalOpen(false);
    setIsDeleting(false);
  };

  const onConfirmDeleteCategory = () => {
    setIsDeleting(true);
    onDeleteCategory(
      itemRequestedForDeletion.id,
      itemRequestedForDeletion.categoryName,
      onCompletedDeleteCategory
    );
  };

  const onRequestEditCategory = (id) => {
    onEditCategory(id);
  };

  const onRequestAddChildCategory = (id) => {
    onAddChildCategory(id);
  };

  const rowLimit = () => {
    if (categories.length < COLLAPSED_MAX_ITEMS) return categories.length;
    if (isCollapsed) return COLLAPSED_MAX_ITEMS;
    return categories.length;
  };

  const _handleRowToggle = (e) => {
    e.preventDefault();
    if (hasNestedChild) {
      const dataID = e.target.closest('td')?.getAttribute('data-id');
      if (dataID) {
        const toggle = !rowsCollapsed[dataID];
        setRowsCollapsed({...rowsCollapsed, [dataID]: toggle});
      }
    }
  };

  const handleRowToggle = (event) => {
    switch (event.type) {
      case "click":
        _handleRowToggle(event);
        break;
      case "keydown":
        if (event.key === "Enter" || event.key === " ") {
        _handleRowToggle(event);
        }
        break;
        default:
    }
  };

  return (
    <div className="slds-m-bottom_x-large" key={groupId}>
      <div className="slds-grid slds-grid_vertical-align-center slds-m-bottom_medium">
        <div className="slds-col slds-grid slds-grid_vertical-align-center">
          <Icon
            category="standard"
            name="quotes"
            className="tds-bg_salesforce"
          />
          <p className="slds-text-heading_small slds-m-left_small">{valueInLocale(I18n.locale, name)}</p>
        </div>
        <div className="slds-grid slds-grid_vertical-align-center slds-grid_align-end">
          <Button
            label={tCat('actions.new_filter')}
            variant="neutral"
            disabled={!canAddCategory}
            onClick={() => onAddCategory(groupId)}
          />
          {!canAddCategory &&
          <Tooltip
            id="new-filter-button-tooltip"
            align="top"
            content={tCat(`actions.new_filter_limit_reached.${groupAPIName}`)}
          >
            <a href="#" className="slds-m-left_x-small" tabIndex="0">
              <Icon
                assistiveText={{label: 'Tooltip with icon'}}
                category="utility"
                name="info"
                size="x-small"
              />
            </a>
          </Tooltip>
          }
        </div>
      </div>
      <table className="slds-table slds-table_fixed-layout slds-table_resizable-cols slds-table_bordered slds-no-row-hover tds-table" role="grid">
        <thead>
          <tr className="slds-line-height_reset">
            <th scope="col">
              <span className="slds-p-horizontal_x-small slds-th__action" style={{display: 'flex'}}>
                <span className="slds-truncate" title="Filter Name">Filter Name</span>
              </span>
            </th>
            <th scope="col" style={{width: '6rem'}}>
              <span className="slds-p-horizontal_x-small slds-th__action" style={{display: 'flex'}}>
                <span className="slds-truncate" title="Actions">Actions</span>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {
            prepareCategories(I18n.locale, categories, rowLimit()).map((item) => {
              let ariaExpanded = null;
              let tabIndex = '';
              if (item.children.length > 0) {
                ariaExpanded = rowsCollapsed[item.id] || false;
                tabIndex = '0';
              }
              return (
                <React.Fragment key={item.id}>
                  <tr style={{cursor: item.children.length > 0 ? 'pointer' : ''}}>
                    <td data-label="Filter Name" role="gridcell" tabIndex={tabIndex} aria-expanded={ariaExpanded} data-id={item.children.length ? item.id : ''} onClick={handleRowToggle} onKeyDown={handleRowToggle}>
                      <div className="slds-truncate" title={item.name}>
                        { item.children.length > 0 &&
                          <Icon
                            ariaHidden="true"
                            category="utility"
                            name="switch"
                            size="x-small"
                            className="slds-button__icon slds-button__icon_left"
                            title="nested category indicator"
                            style={{transform: rowsCollapsed[item.id] ? 'rotate(180deg)' : 'rotate(0deg)'}}
                          />
                        }
                        {item.name}
                      </div>
                    </td>
                    <td className="tds-table__cell" data-label="Actions" role="gridcell" style={{width: '6rem'}}>
                      <ActionsDataCell
                        item={item}
                        title={tCat('columns.actions')}
                        onRequestAddChild={onRequestAddChildCategory}
                        onRequestDelete={onRequestDeleteCategory}
                        onRequestEdit={onRequestEditCategory}
                        groupAPIName={groupAPIName}
                        publicTrailmaker={publicTrailmaker}
                        hasNestedChild={hasNestedChild}
                      />
                    </td>
                  </tr>
                  {
                    item.children.length > 0 &&
                      <tr style={{display: rowsCollapsed[item.id] ? 'contents' : 'none'}}>
                        <td data-label="Filter Name" role="gridcell" colSpan="2" style={{padding: '0rem 0.5rem 0rem 2.25rem', border: 'none'}}>
                          <table className="slds-table slds-table_fixed-layout slds-table_resizable-cols slds-table_bordered slds-no-row-hover tds-table" role="grid" style={{border: 'none'}}>
                            <tbody>
                              {
                                item.children.map((child) => {
                                  return (
                                    <tr key={child.id}>
                                      <td data-label="Filter Name" role="gridcell">
                                        <div className="slds-truncate" title={child.name}>{child.name}</div>
                                      </td>
                                      <td className="tds-table__cell" data-label="Actions" role="gridcell" style={{width: '5.5rem'}}>
                                        <ActionsDataCell
                                            item={child}
                                            title={tCat('columns.actions')}
                                            onRequestDelete={onRequestDeleteCategory}
                                            onRequestEdit={onRequestEditCategory}
                                            groupAPIName={groupAPIName}
                                            publicTrailmaker={publicTrailmaker}
                                            isChild={true}
                                          />
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                        </td>
                      </tr>
                  }
                </React.Fragment>
              )
            })
          }
        </tbody>
      </table>
      {categories.length > COLLAPSED_MAX_ITEMS &&
      <Button
        label={isCollapsed ? tCat('actions.view_all') : tCat('actions.view_less')}
        onClick={() => setIsCollapsed(!isCollapsed)}
        variant="neutral"
        className="slds-m-top_large slds-align_absolute-center"
      />
      }
      <CategoryDeleteModal
        isOpen={isDeleteModalOpen}
        categoryGroupAPIName={groupAPIName}
        categoryId={itemRequestedForDeletion ? itemRequestedForDeletion.id : ''}
        categoryName={itemRequestedForDeletion ? itemRequestedForDeletion.categoryName : ''}
        isCategoryProtected={
          itemRequestedForDeletion ? itemRequestedForDeletion.isProtected : false
        }
        hasCategoryChildren={
          itemRequestedForDeletion ? itemRequestedForDeletion.hasChildren : false
        }
        onDelete={onConfirmDeleteCategory}
        isDeleting={isDeleting}
        onRequestClose={onCancelDeleteCategory}
      />
    </div>
  );
};

const translationsShape = PropTypes.shape({
  locale: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

CategoryTable.propTypes = {
  groupId: PropTypes.string.isRequired,
  groupAPIName: PropTypes.string.isRequired,
  name: PropTypes.arrayOf(translationsShape).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.arrayOf(translationsShape).isRequired,
      protected: PropTypes.bool,
    })
  ).isRequired,
  onDeleteCategory: PropTypes.func.isRequired,
  onAddCategory: PropTypes.func.isRequired,
  onAddChildCategory: PropTypes.func.isRequired,
  canAddCategory: PropTypes.bool.isRequired,
};

export default CategoryTable;
