import React from 'react';
import PropTypes from 'prop-types';
import {t} from 'lib/I18n';
import WebComponent from 'components/WebComponent';

export default class EmailSelect extends React.Component {
  static propTypes = {
    emails: PropTypes.array.isRequired,
    unitType: PropTypes.string,
    onChange: PropTypes.func,
  }

  renderEmailOption = (email, index) => (
    <option key={index} value={email}>{email}</option>
  )
    
  renderEmailOptions = (emails) => emails.map(this.renderEmailOption);

  render() {
    return (
      <WebComponent
        tagName="tds-select"
        attrs={{
          label: t(`challenge.assessment.slack.email_address_select`),
        }}
        events={{change: (e) => {
          this.props.onChange(e.detail);
        }}}
      >
        {this.renderEmailOptions(this.props.emails)}
      </WebComponent>
    );
  }
}
