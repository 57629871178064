import railsFetch from 'lib/railsFetch';

const FAVORITES_PATH = '/users/favorites';

function updateFavorite({ type, apiName, favorited, contentType }) {
  const method = favorited ? 'POST' : 'DELETE';
  const favoritableType = contentType === "trail_v2" ? "learningpath" : type;
  
  return railsFetch({
    url: FAVORITES_PATH,
    method,
    data: {
      favorite: {
        favoritable_api_name: apiName,
        favoritable_type: favoritableType,
      },
    },
  });
}

export default { updateFavorite };
