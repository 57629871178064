import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {
  Button,
  Combobox,
  Input,
  Tooltip,
} from '@devforce/tds-react';
import { buildScopeTranslate } from '../../../../lib/I18n';

const tTranslation = buildScopeTranslate('views.trailmaker.settings.category.category_translation');
const errorText = (errorKeys, vars) => {
  if (isEmpty(errorKeys)) return null;

  return errorKeys.map((k) => (
    tTranslation(`errors.${k}`, vars)
  )).join(' ');
};

const CategoryTranslation = ({
  id,
  groupAPIName,
  label,
  labelErrors,
  labelMaxLength,
  language,
  languageErrors,
  languageOptions,
  showFieldLabels,
  showRemoveButton,
  onUpdateLabel,
  onUpdateLanguage,
  onRemove,
  readOnlyLanguage,
  publicTrailmaker,
}) => (
  <div className="slds-form-element slds-form-element_compound slds-m-bottom_small">
    <div className="slds-form-element__control">
      <div className="slds-form-element__row">
        <div className="slds-size_1-of-2">
          <label className="th-sr-only" htmlFor={`input-category-label${id}`}>{readOnlyLanguage ?
                tTranslation(`category_label_placeholder.${groupAPIName}`) :
                tTranslation('category_label_placeholder_subsequent')}
          </label>
          <Input
            id={`input-category-label${id}`}
            aria-describedby={readOnlyLanguage ?
              tTranslation(`category_label_placeholder.${groupAPIName}`) :
              tTranslation('category_label_placeholder_subsequent')}
            label={showFieldLabels ? tTranslation('category_label') : ''}
            placeholder={readOnlyLanguage ?
              tTranslation(`category_label_placeholder.${groupAPIName}`) :
              tTranslation('category_label_placeholder_subsequent')}
            value={label}
            onChange={(_, {value}) => onUpdateLabel(id, value, 'label')}
            maxLength={labelMaxLength.toString()}
            fieldLevelHelpTooltip={
              <Tooltip
                id={`input-category-label-tooltip-${id}`}
                align="top left"
                content={tTranslation(`category_label_tooltip.${groupAPIName}`)}
              />
            }
            errorText={errorText(labelErrors, {max_length: labelMaxLength})}
            required
          />
        </div>
        <div className="slds-size_1-of-2 slds-is-relative">
          <div className="slds-small-size_8-of-12 slds-large-size_10-of-12" style={{ display: 'inline-block' }}>
            { (publicTrailmaker && language && readOnlyLanguage) ? 
              <div className="slds-m-left_small slds-is-absolute" style={{ bottom: '.5rem' }}>{language.label}</div> :
              <div>
                <label className="th-sr-only" htmlFor={`input-category-language${id}`}>{tTranslation('category_language_placeholder')}</label>
                <Combobox
                  id={`input-category-language${id}`}
                  events={{
                    onSelect: (e, {selection}) => {
                      if (selection[0]) onUpdateLanguage(id, selection[0], 'language');
                    }
                  }}
                  labels={{
                    label: showFieldLabels ? tTranslation('category_language') : '',
                    placeholderReadOnly: tTranslation('category_language_placeholder'),
                  }}
                  options={languageOptions}
                  selection={[language]}
                  fieldLevelHelpTooltip={
                    <Tooltip
                      id={`input-category-language-tooltip-${id}`}
                      align="top left"
                      content={tTranslation('category_language_tooltip')} 
                    />
                  }
                  variant="readonly"
                  disabled={!!readOnlyLanguage}
                  required
                  errorText={errorText(languageErrors)}
                />
              </div>
            }
          </div>
          { showRemoveButton &&
            <Button
              id={`button-category-delete-${id}`}
              assistiveText={{ icon: tTranslation('delete') }}
              iconCategory="action"
              iconName="remove"
              iconSize="x-small"
              iconVariant="bare"
              onClick={() => onRemove(id)}
              variant="icon"
            />
          }
        </div>
      </div>
    </div>
  </div>
);

const langShape = PropTypes.shape({
  id: PropTypes.string,
  abbr: PropTypes.string,
  label: PropTypes.string
});

CategoryTranslation.propTypes = {
  id: PropTypes.string.isRequired,
  groupAPIName: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelErrors: PropTypes.arrayOf(PropTypes.string),
  labelMaxLength: PropTypes.number,
  language: langShape,
  languageOptions: PropTypes.arrayOf(langShape),
  languageErrors: PropTypes.arrayOf(PropTypes.string),
  showFieldLabels: PropTypes.bool,
  showRemoveButton: PropTypes.bool,
  onUpdateLabel: PropTypes.func.isRequired,
  onUpdateLanguage: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  readOnlyLanguage: PropTypes.bool,
};

CategoryTranslation.defaultProps = {
  label: '',
  language: null,
  languageOptions: [],
  labelMaxLength: 80,
  showFieldLabels: false,
  showRemoveButton: true,
  readOnlyLanguage: false,
};

export default CategoryTranslation;
