import React from 'react';
import PropTypes from 'prop-types';
import serialize from 'form-serialize';
import {getAuthToken} from 'lib/railsFetch';

export default class Form extends React.PureComponent {
  static propTypes = {
    action: PropTypes.string.isRequired,
    method: PropTypes.string.isRequired,
    onSubmit: PropTypes.func,
    className: PropTypes.string,
    authenticityToken: PropTypes.string,
    children: PropTypes.node
  };

  state = {
    authenticityToken: this.props.authenticityToken || ''
  };

  componentDidMount() {
    if (!this.state.authenticityToken) {
      this.setState({authenticityToken: getAuthToken()});
    }
  }

  onSubmit = (e) => {
    const {onSubmit} = this.props;
    if (!onSubmit) return;
    e.preventDefault();

    const data = serialize(this.form, {hash: true, empty: true});
    onSubmit(data);
  }

  formMethod() {
    return this.props.method.toLowerCase() === 'get' ? 'get' : 'post';
  }

  render() {
    const {
      children,
      method,
      disableButton,
      ...otherProps
    } = this.props;

    const {
      authenticityToken
    } = this.state;

    return (
      <form
        {...otherProps}
        ref={(form) => { this.form = form; }}
        acceptCharset="UTF-8"
        method={this.formMethod()}
        onSubmit={(e) => { disableButton && disableButton(true); this.onSubmit(e); }}>
        <input name="utf8" type="hidden" value="&#x2713;" />
        <input type="hidden" name="_method" value={method} />
        <input type="hidden" name="authenticity_token" value={authenticityToken} />
        {children}
      </form>
    );
  }
}
